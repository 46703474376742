import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import M from 'materialize-css'

const CreatePost = () => {
    const history = useHistory()
    const [title, setTitle] = useState('')
    const [post, setPost] = useState('')
    const [image, setImage] = useState('')
    const [url, setUrl] = useState('')

    useEffect(() => {
        if(url){
            fetch('/createPost', {
                method: 'post',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('jwt')
                },
                body: JSON.stringify({
                    title,
                    body: post,
                    pic: url
                })
            })
            .then(res => res.json())
            .then(data => {
                if (data.error) {
                    M.toast({ html: data.error, classes: "#e53935 red darken-1" })
                } else {
                    M.toast({ html: "Post Created Successfully", classes: "#00c853 green accent-4" })
                    history.push('/')
                }
            }).catch(error => {
                console.log(error)
            })
        }
    },[url])

    const postDetails = () => {
        if(image){
            const data = new FormData()
            data.append('file', image)
            data.append('upload_preset', 'insta-clone')
            data.append('cloud_name', 'dgxvqhybh')

            fetch('https://api.cloudinary.com/v1_1/dgxvqhybh/image/upload', {
                method: 'post',
                body: data
            })
            .then(res => res.json())
            .then(data => {
                setUrl(data.url)
            })
            .catch(err => {
                console.log(err)
            })
        } else {
            console.log('posting without image')
            fetch('/createPost', {
                method: 'post',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('jwt')
                },
                body: JSON.stringify({
                    title,
                    body: post
                })
            })
            .then(res => res.json())
            .then(data => {
                if (data.error) {
                    M.toast({ html: data.error, classes: "#e53935 red darken-1" })
                } else {
                    M.toast({ html: "Post Created Successfully", classes: "#00c853 green accent-4" })
                    history.push('/')
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    return (
        <div className="card input-filed grey darken-4" style={{
            margin: "30px auto",
            maxWidth: "500px",
            padding: "20px",
            textAlign: "center"
        }}>
            <input
                type="text"
                placeholder="title"
                className="input-white validate"
                value={title}
                onChange={(e) => { setTitle(e.target.value) }}
            />
            <input
                type="text"
                placeholder="body"
                className="input-white validate"
                value={post}
                onChange={(e) => { setPost(e.target.value) }}
            />
            <div className="file-field input-field">
                <div className="btn #64b5f6 blue darken-1">
                    <span>Upload Image</span>
                    {/* <input type="file" onChange={(e) => {console.log(e.target.files[0])}}/> */}
                    <input type="file" onChange={(e) => { setImage(e.target.files[0]) }} />
                </div>
                <div className="file-path-wrapper">
                    <input className="file-path validate white-text" type="text" />
                </div>
            </div>
            <button
                className="btn waves-effect waves-light #64b5f6 blue darken-1"
                onClick={postDetails}
            >Submit Post</button>
        </div>
    )
}

export default CreatePost